<template>
  <div class="container">
    <div class="login-title">电律管理后台</div>
    <div class="login login-inner">
      <el-form
          :model="formInline"
      >
        <el-form-item label="">
          <el-input prefix-icon="el-icon-user" v-model="formInline.username" placeholder="请输入用户名" @keyup.enter.native="onLogin"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input prefix-icon="el-icon-lock" type="password" v-model="formInline.password" placeholder="请输入密码" @keyup.enter.native="onLogin"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" type="primary" @click="onLogin">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'PcLoginView',
  data () {
    return {
      formInline: {
        username: '',
        password: ''
      }
    }
  },
  mounted () {

  },
  methods: {
    ...mapActions('user', ['login']),
    async onLogin () {
      const { username, password } = this.formInline
      if (!username) {
        this.$message({
          message: '请输入用户名',
          type: 'error',
          duration: 2 * 1000
        })
        return false
      } else if (!password) {
        this.$message({
          message: '请输入密码',
          type: 'error',
          duration: 2 * 1000
        })
        return false
      }
      await this.login(this.formInline)
      await this.$router.push('/dashboard')
    }
  }
}
</script>
<style scoped lang="scss">
.container {
  height: 100vh;
  background: #2d3a4b;
  color: #ffffff;
}
.login-title {
  text-align: center;
  padding-top: 150px;
  font-size: 20px;
  padding-bottom: 20px;
}
.login {
  width: 400px;
  margin: 0 auto;
  .btn {
    width: 100%;
  }
}
</style>
<style lang="scss">
.login-inner {
  .el-input {
    font-size: 18px;
  }
  .el-input__inner {
    height: 46px;
    line-height: 46px;
    font-size: 16px;
  }
  .el-button {
    height: 46px;
    font-size: 16px;
  }
}
</style>
